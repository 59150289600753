import React from "react";
import {Routes, Route} from 'react-router-dom';
import { AuthContextProvider } from "../services/AuthContext";
import { ProtectedRoutesAdm } from "./ProtectedRoutes";
import Login from '../pages/Login/Login';
import Home from "../pages/Home/Home";
import GeralHistorico from "../pages/Home/GeralHistorico";
import Historico from "../pages/Projetos/Historico";
import FaturamentoHistorico from "../pages/Projetos/FaturamentoHistorico";
import QtdFatHistorico from "../pages/Projetos/QtdFatHistorico";
import Custos from "../pages/Custos/Custos";
import CustoNovo from "../pages/Custos/CustoNovo";
import QuantitativoPerioddo from "../pages/Projetos/QuantitativoPerioddo";
import FaturamentoPeriodo from "../pages/Projetos/FaturamentoPeriodo";
import QtdFatPeriodo from "../pages/Projetos/QtdFatPeriodo";
import QuatroAnos from "../pages/Projetos/QuatroAnos";
import QuatroAnosDataAtual from "../pages/Projetos/QuatroAnosDataAtual";
import QuatroAnosMesAtual from "../pages/Projetos/QuatroAnosMesAtual";
import MaioresClientes from "../pages/Clientes/MaioresClientes";
import MaioresClientesFat from "../pages/Clientes/MaioresClientesFat";
import ClientesAnoQuantidade from "../pages/Clientes/ClientesAnoQuantidade";
import ClientesHistoricoQtd from "../pages/Clientes/ClientesHistoricoQtd";
import MaioresClientesAno from "../pages/Clientes/MaioresClientesAno";
import ClientesFaturamentoAno from "../pages/Clientes/ClientesFaturamentoAno";
import ClientesPeriodoQuantidade from "../pages/Clientes/ClientesPeriodoQuantidade";
import Clientes6Meses from "../pages/Clientes/Clientes6Meses";
import ClientesAnoFaturamento from "../pages/Clientes/ClientesAnoFaturamento";
import ClientesFaturamentoHistorico from "../pages/Clientes/ClientesFaturamentoHistorico";
import ClientesFaturamentoPeriodo from "../pages/Clientes/ClientesFaturamentoPeriodo";
import MaioresPaises from "../pages/Paises/MaioresPaises";
import Paises6meses from "../pages/Paises/Paises6meses";
import MaioresPaisesFatHistorico from "../pages/Paises/MaioresPaisesFatHistorico";
import MaioresPaisesAno from "../pages/Paises/MaioresPaisesAno";
import MaioresPaisesFaturamentoAno from "../pages/Paises/MaioresPaisesFaturamentoAno";
import PaisesHistoricoQtd from "../pages/Paises/PaisesHistoricoQtd";
import PaisesFaturamentoHistorico from "../pages/Paises/PaisesFaturamentoHistorico";
import PaqisesAnoQuantidade from "../pages/Paises/PaqisesAnoQuantidade";
import PaisesFaturamentoAno from "../pages/Paises/PaisesFaturamentoAno";
import PaisesPeriodoQuantidade from "../pages/Paises/PaisesPeriodoQuantidade";
import PaisesFaturamentoPeriodo from "../pages/Paises/PaisesFaturamentoPeriodo";
import MaioresJurisdicoes from "../pages/Jurisdicoes/MaioresJurisdicoes";
import Jurisdicoes6Meses from "../pages/Jurisdicoes/Jurisdicoes6Meses";
import MaioresJurisdicoesFatHistorico from "../pages/Jurisdicoes/MaioresJurisdicoesFatHistorico";
import JurisdicoesAnoQuantidade from "../pages/Jurisdicoes/JurisdicoesAnoQuantidade";
import MaioresJurisdicoesAno from "../pages/Jurisdicoes/MaioresJurisdicoesAno";
import MaioresJurisciçõesFatAno from "../pages/Jurisdicoes/MaioresJurisciçõesFatAno";
import JurisdicoesHistoricoQtd from "../pages/Jurisdicoes/JurisdicoesHistoricoQtd";
import JurisdicoesFaturamentoHistorico from "../pages/Jurisdicoes/JurisdicoesFaturamentoHistorico";
import JurisdicoesAnoFaturamento from "../pages/Jurisdicoes/JurisdicoesAnoFaturamento";
import JurisdicoesPeriodoQuant from "../pages/Jurisdicoes/JurisdicoesPeriodoQuant";
import JurisdiçõesFaturamentoPeriodo from "../pages/Jurisdicoes/JurisdiçõesFaturamentoPeriodo";
import JurisdicoesPorPais from "../pages/Jurisdicoes/JurisdicoesPorPais";
import JurisdiçõesPorCliente from "../pages/Jurisdicoes/JurisdiçõesPorCliente";
import ClientesPorJurisdição from "../pages/Clientes/ClientesPorJurisdição";
import PaisesPorJurisdicao from "../pages/Paises/PaisesPorJurisdicao";
import ClientesQuatroAnosDataAtutual from "../pages/Clientes/ClientesQuatroAnosDataAtutual";
import ClientesQuatroAnos from "../pages/Clientes/ClientesQuatroAnos";
import Clientes5AnosFaturamento from "../pages/Clientes/Clientes5AnosFaturamento";
import Relatorios from "../pages/Home/Relatorios";
import ClientesLucro from "../pages/Clientes/ClientesLucro";
import ClientesQuantidade from "../pages/Clientes/ClientesQuantidade";

export const RouteList =()=>{

    return(
        <AuthContextProvider>
            <Routes>
               
                <Route path="/" element={
                    <ProtectedRoutesAdm>
                    <Home />
                    </ProtectedRoutesAdm>
                } />
                <Route path="/historico" element={
                    <ProtectedRoutesAdm>
                    <GeralHistorico />
                    </ProtectedRoutesAdm>
                } />
               
                <Route path="/projetohistorico" element={
                    <ProtectedRoutesAdm>
                    <Historico />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/relatorio" element={
                    <ProtectedRoutesAdm>
                    <Relatorios />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/projetofaturamentohistorico" element={
                    <ProtectedRoutesAdm>
                    <FaturamentoHistorico />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/projetoquatroanos" element={
                    <ProtectedRoutesAdm>
                    <QuatroAnos />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/projetoquatroanosdataatual" element={
                    <ProtectedRoutesAdm>
                    <QuatroAnosDataAtual />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/projetoquatroanosmesatual" element={
                    <ProtectedRoutesAdm>
                    <QuatroAnosMesAtual />
                    </ProtectedRoutesAdm>
                    
              
                } />

                <Route path="/qtdxfathistorico" element={
                    <ProtectedRoutesAdm>
                    <QtdFatHistorico />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/quantidadeperiodo" element={
                    <ProtectedRoutesAdm>
                    <QuantitativoPerioddo />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/faturamentoperiodo" element={
                    <ProtectedRoutesAdm>
                    <FaturamentoPeriodo />
                    </ProtectedRoutesAdm>
                    
              
                } />

                <Route path="/qtdxfatperiodo" element={
                    <ProtectedRoutesAdm>
                    <QtdFatPeriodo />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/custos" element={
                    <ProtectedRoutesAdm>
                    <Custos />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/novocusto" element={
                    <ProtectedRoutesAdm>
                    <CustoNovo />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/maioreclientes" element={
                    <ProtectedRoutesAdm>
                    <MaioresClientes />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/clientesquantidade" element={
                    <ProtectedRoutesAdm>
                    <ClientesQuantidade />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/maioreclientesano" element={
                    <ProtectedRoutesAdm>
                    <MaioresClientesAno />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/maioreclientesfat" element={
                    <ProtectedRoutesAdm>
                    <MaioresClientesFat />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/clientesanoquantidade" element={
                    <ProtectedRoutesAdm>
                    <ClientesAnoQuantidade />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/clienteslucratividade" element={
                    <ProtectedRoutesAdm>
                    <ClientesLucro />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/clientesperiodoquantidade" element={
                    <ProtectedRoutesAdm>
                    <ClientesPeriodoQuantidade />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/clientesanofaturamento" element={
                    <ProtectedRoutesAdm>
                    <ClientesFaturamentoAno />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/clientes5anosfaturamento" element={
                    <ProtectedRoutesAdm>
                    <Clientes5AnosFaturamento />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/clientesfaturamentoano" element={
                    <ProtectedRoutesAdm>
                    <ClientesAnoFaturamento />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/clienteshistoricoquantidade" element={
                    <ProtectedRoutesAdm>
                    <ClientesHistoricoQtd />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/clientesfaturamentohistorico" element={
                    <ProtectedRoutesAdm>
                    <ClientesFaturamentoHistorico />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/clientesfaturamentoperiodo" element={
                    <ProtectedRoutesAdm>
                    <ClientesFaturamentoPeriodo />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/clientesuseismeses" element={
                    <ProtectedRoutesAdm>
                    <Clientes6Meses />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/clientesquatroanosdataatual" element={
                    <ProtectedRoutesAdm>
                    <ClientesQuatroAnosDataAtutual />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/clientesquatroanos" element={
                    <ProtectedRoutesAdm>
                    <ClientesQuatroAnos />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/maiorepaises" element={
                    <ProtectedRoutesAdm>
                    <MaioresPaises />
                    </ProtectedRoutesAdm>
                    
              
                } />
                  <Route path="/paisesseismeses" element={
                    <ProtectedRoutesAdm>
                    <Paises6meses />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/maiorepaisesfat" element={
                    <ProtectedRoutesAdm>
                    <MaioresPaisesFatHistorico />
                    </ProtectedRoutesAdm>
                    
              
                } />

                <Route path="/maiorepaisesano" element={
                    <ProtectedRoutesAdm>
                    <MaioresPaisesAno/>
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/maiorepaisesfaturamentoano" element={
                    <ProtectedRoutesAdm>
                    <MaioresPaisesFaturamentoAno/>
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/paiseshistoricoquantidade" element={
                    <ProtectedRoutesAdm>
                    <PaisesHistoricoQtd />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/paisesfaturamentohistorico" element={
                    <ProtectedRoutesAdm>
                    <PaisesFaturamentoHistorico />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/paisesanoquantidade" element={
                    <ProtectedRoutesAdm>
                    <PaqisesAnoQuantidade />
                    </ProtectedRoutesAdm>
                    
              
                } />
                   <Route path="/paisesfaturamentoano" element={
                    <ProtectedRoutesAdm>
                    <PaisesFaturamentoAno />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/paisesperiodoquantidade" element={
                    <ProtectedRoutesAdm>
                    <PaisesPeriodoQuantidade />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/paisesperiodofaturamento" element={
                    <ProtectedRoutesAdm>
                    <PaisesFaturamentoPeriodo />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/maioresjurisdicoes" element={
                    <ProtectedRoutesAdm>
                    <MaioresJurisdicoes />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/jurisdicoesseismeses" element={
                    <ProtectedRoutesAdm>
                    <Jurisdicoes6Meses />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/maioresjurisdicoesfat" element={
                    <ProtectedRoutesAdm>
                    <MaioresJurisdicoesFatHistorico />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/maioresjurisdicoesano" element={
                    <ProtectedRoutesAdm>
                    <MaioresJurisdicoesAno />
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/maiorejurisdicoesfaturamentoano" element={
                    <ProtectedRoutesAdm>
                    <MaioresJurisciçõesFatAno/>
                    </ProtectedRoutesAdm>
                    
              
                } />
                 <Route path="/jurisdicoeshistoricoquantidade" element={
                    <ProtectedRoutesAdm>
                    <JurisdicoesHistoricoQtd />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/jurisdicoesfaturamentohistorico" element={
                    <ProtectedRoutesAdm>
                    <JurisdicoesFaturamentoHistorico />
                    </ProtectedRoutesAdm>
                    
              
                } />

                <Route path="/jurisdicoesanoquantidade" element={
                    <ProtectedRoutesAdm>
                    <JurisdicoesAnoQuantidade />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/jurisdicoesanofaturamento" element={
                    <ProtectedRoutesAdm>
                    <JurisdicoesAnoFaturamento />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/jurisdicoesperiodoquantidade" element={
                    <ProtectedRoutesAdm>
                    <JurisdicoesPeriodoQuant />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/jurisdicoesperiodofaturamento" element={
                    <ProtectedRoutesAdm>
                    <JurisdiçõesFaturamentoPeriodo />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/jurisdicoesporpais" element={
                    <ProtectedRoutesAdm>
                    <JurisdicoesPorPais />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/jurisdicoesporcliente" element={
                    <ProtectedRoutesAdm>
                    <JurisdiçõesPorCliente />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/clientesporjurisdicao" element={
                    <ProtectedRoutesAdm>
                    <ClientesPorJurisdição />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/paisesporjurisdicao" element={
                    <ProtectedRoutesAdm>
                    <PaisesPorJurisdicao />
                    </ProtectedRoutesAdm>
                    
              
                } />
                <Route path="/login" element={<Login/>} />

            </Routes>
        </AuthContextProvider>
    );

}