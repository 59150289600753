import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function ClientesQuantidade() {

    const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [loading, setLoading]=useState(true)
    const [todos , setTodos]=useState([])
    const [lista, setLista]=useState(false)
    const [nprojs, setNprojs]=useState([])
   
  

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      const r = query(clientsCollectionRef) 
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
      })
          const change2 = onSnapshot(r, snapshot=>{
          setNprojs(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
       return()=>{change();change2()}
  
   },[]);

    const handleLista = ()=>{
        let clientes = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  ){return val}}).map((i)=>i.data.projClient)
        let clientesemDuplicados = clientes.filter((v, i, s) => {
          return s.indexOf(v) === i;
        });

        var sistema = (nome)=>{
           let number1 = projetos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome ){return val}})
           let numbers =  number1.length
           return(numbers)
        }
         var antigos = (nome)=>{
            let number1 = nprojs.filter((val)=>{if(val.data.clientName === nome && val.data.clientStatus === 'Ativo'){return val}}).map((i)=>i.data.clientProjects? i.data.clientProjects:0)
            let numbers = parseInt(number1);
            return(numbers)
        }
        
        var clientesA = clientesemDuplicados.map((item, index)=>(
          {
            "id": index,
            "name":item,
            "quantidadeSitema":sistema(item),
            "quantidadeAntigo":antigos(item),
        }
      ))
       var clientes1 = clientesA.map((item, index)=>(
          {
            "id": index,
            "name":item.name,
            "quantidadeSitema":item.quantidadeSitema,
            "quantidadeAntigo":item.quantidadeAntigo,
            "quantidadeTotal":isNaN(item.quantidadeAntigo)? 0:item.quantidadeAntigo +item.quantidadeSitema,
        }
      ))
      var clientesOrdenados = clientes1.sort((a,b)=>{
          if (a.quantidadeTotal < b.quantidadeTotal) {
            return 1;
          }
          if (a.quantidadeTotal > b.quantidadeTotal) {
            return -1;
          }
          return 0;
        })
    setClientes(clientesOrdenados)
    setLista(true)
    }
  return (
    <div>
        <Header />
   {loading &&
      <div className="pageTitle">
      <div>Carregando...</div>
    </div>
   }
   {!loading &&
     <>
     <div className="pageTitle">
       <div>Clientes Quantidade Total de Projetos</div>
       <button onClick={handleLista}>Gerar Lista</button>
     </div>
    { lista &&
    <div className="pageContent collum">
         <div className="contentLine2" style={{width:'70%', margin:'10px auto'}}>Clientes Com Projetos</div>
          <div className="linesClientes">
                  <div className="lines-itemC" style={{fontWeight:800}}><p>Nome Cliente</p></div>
                  <div className="lines-itemC" style={{fontWeight:800}}><p>Antigos</p></div>
                  <div className="lines-itemC" style={{fontWeight:800}}><p>Sistema</p></div>
                  <div className="lines-itemC" style={{fontWeight:800}}><p>Total</p></div>

              </div>
          <div style={{marginBottom:'50px'}}>
              {clientes.map((item, index)=>(
              <div className="linesClientes" key={index}>
                  <div className="lines-itemC"><p>{item.name}</p></div>
                  <div className="lines-itemC"><p>{item.quantidadeAntigo}</p></div>
                  <div className="lines-itemC"><p>{item.quantidadeSitema}</p></div>
                  <div className="lines-itemC"><p>{item.quantidadeTotal}</p></div>
              </div>
              ))}        
        </div>
     </div>
     }
  
     
     </>     
   }
    </div>
  )
}

export default ClientesQuantidade